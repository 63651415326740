/* eslint-disable react/no-danger */

import {
  useEffect, useRef, useState,
} from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { observer } from 'mobx-react';
import MainLayout from '@/layout/MainLayout';
import reRouteTo from '@/utils/api/sessionPass';
import {
  BasicInfo, NewsBanner, QuoteMarquee,
} from '@/components/pages/home';
import pageProps from '@/layout/pageProps';
import { useStore } from '@/utils/store';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';
import {
  closeZendesk, getPlatform, openZendesk, referralRUF,
} from '@/utils/functions/common';
import Logo from '@/layout/header/icons/Bitop';
import BackGroundColor from '@/components/pages/home/imgs/bgColor.png';
import bannerImage from '@/components/pages/home/imgs/bg.png';
import TradingInfoImage from '@/components/pages/home/imgs/tradingInfoImage.png';
import Security from '@/components/pages/home/imgs/security.png';
import Protection from '@/components/pages/home/imgs/protection.png';
import StartEarningLeft from '@/components/pages/home/imgs/leftLight.png';
import StartEarningRight from '@/components/pages/home/imgs/rightLight.png';
import { DownloadPage } from '@/components/common/DownloadPage';
import SideBarIcon from '@/components/pages/center/imgs/SideBarIcon';

import styles from '@/styles/pages/home.module.scss';

function Home() {
  const [popularTab, setPopularTab] = useState(0);
  const [visible, setVisible] = useState(true);
  const { t, i18n } = useTranslation('pages', { keyPrefix: 'home' });
  const { user } = useStore().auth;
  const router = useRouter();
  const { phrase } = useH5StringBuilder();

  const intervalFunction = useRef(null);

  function closeFunc() {
    setVisible(false);
  }

  function handleRedirect() {
    if (typeof window === 'undefined') return '';
    if (getPlatform() === 'pad' || getPlatform() === 'pc') {
      return reRouteTo('/login');
    }

    const { f, ru } = router.query;

    if (f && ru) {
      let paramsString = '';

      if (ru) {
        paramsString += `ru=${ru}&`;
      }

      if (f) {
        paramsString += `f=${f}&`;
      }

      if (paramsString.endsWith('&')) {
        paramsString = paramsString.slice(0, -1);
      }
      return reRouteTo(`${window.location.protocol}//m.${window.location.hostname.split('.').slice(-2).join('.')}${i18n.language === 'en' ? '/eng' : '/zh'}/login/login${paramsString}`);
    }
    return reRouteTo(`${phrase}/login/login${referralRUF()}`);
  }

  useEffect(() => {
    if (getPlatform() !== 'pad' && getPlatform() !== 'pc') {
      closeZendesk();
      setTimeout(() => {
        clearInterval(intervalFunction.current);
      }, 80000);
      intervalFunction.current = setInterval(() => {
        closeZendesk();
      }, 1000);
    }

    return () => {
      clearInterval(intervalFunction.current);
      openZendesk(true);
    };
  }, []);

  function handleRedirect3() {
    closeFunc();
    router.push('download');
  }

  return (
    <MainLayout>
      <div className={styles.page}>
        <div className={styles.pageBackground}>
          <Image
            priority
            alt="pageBackground"
            src={BackGroundColor}
            layout="responsive"
            objectFit="cover"
          />
        </div>
        <div className={styles.pageContent}>
          <div className={styles.banner}>
            <div className={styles.bannerContent}>
              <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('banner.intro.1st') }} />
              <div className={styles.h2} dangerouslySetInnerHTML={{ __html: t('banner.intro.2nd') }} />
              {!user ? (
                <div
                  className={styles.loadingButton}
                  onKeyDown={handleRedirect}
                  onClick={handleRedirect}
                  data-path="/"
                  role="presentation"
                >
                  <p>{t('banner.getStart')}</p>
                  <div className={styles.arrow}><SideBarIcon icon="arrowRight" /></div>
                </div>
              ) : <div className={styles.loadingButton2} />}
            </div>
            <div className={styles.bannerImage}>
              <Image alt="banner" src={bannerImage} objectFit="contain" priority />
            </div>
            {
              visible && (
                <div className={styles.smallDownloadPanel}>
                  <div className={styles.smallDownload}>
                    <div className={styles.content}>
                      <div className={styles.contentLeft}>
                        <Logo />
                        <div className={styles.contentUpDown}>
                          <div className={styles.contentUp}>
                            {t('banner.smallDownload.appName')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.contentRight}
                        onKeyDown={handleRedirect3}
                        onClick={handleRedirect3}
                        role="presentation"
                      >
                        {t('banner.smallDownload.download')}
                      </div>
                      <div
                        onKeyDown={closeFunc}
                        onClick={closeFunc}
                        role="presentation"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 -2 20 20" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M15.1597 13.5145L11.6453 10L15.1597 6.48551C15.6134 6.03184 15.6134 5.29393 15.1597 4.84026C14.7061 4.38658 13.9682 4.38658 13.5145 4.84026L10 8.35474L6.48551 4.84026C6.03184 4.38658 5.29393 4.38658 4.84026 4.84026C4.38658 5.29393 4.38658 6.03184 4.84026 6.48551L8.35474 10L4.84026 13.5145C4.38658 13.9682 4.38658 14.7061 4.84026 15.1597C5.29393 15.6134 6.03184 15.6134 6.48551 15.1597L10 11.6453L13.5145 15.1597C13.9682 15.6134 14.7061 15.6134 15.1597 15.1597C15.6134 14.7061 15.6134 13.9682 15.1597 13.5145Z" fill="#D9D9D9" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div className={styles.quoteWheel}>
            <BasicInfo />
          </div>

          <div className={styles.popularInfo}>
            <div className={styles.popularInfotitle}>
              <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('popularInfo.title') }} />
            </div>
            <div className={styles.popularTabTitle}>
              <div
                className={popularTab === 0 ? styles.selected : ''}
                onClick={() => setPopularTab(0)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    setPopularTab(0);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {t('popularInfo.subTitle1')}
              </div>
              <div
                className={popularTab === 1 ? styles.selected : ''}
                onClick={() => setPopularTab(1)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    setPopularTab(1);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {t('popularInfo.subTitle2')}
              </div>
              <div
                className={popularTab === 2 ? styles.selected : ''}
                onClick={() => setPopularTab(2)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    setPopularTab(2);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {t('popularInfo.subTitle3')}
              </div>
            </div>
            <div className={styles.popularSpot}>
              <QuoteMarquee popularTab={popularTab} />
            </div>
            <div className={styles.market}>
              <NewsBanner />
            </div>
          </div>

          <div className={styles.tradingInfo}>
            <div className={styles.tradingInfotitle}>
              <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('tradingInfo.title') }} />
              <h2 dangerouslySetInnerHTML={{ __html: t('tradingInfo.des') }} />
              <div className={styles.tradingInfoData}>
                <div>
                  <p className={styles.data}>3000+</p>
                  <p className={styles.label}>{t('tradingInfo.infoLabel.eliteTraders')}</p>
                </div>
                <div>
                  <p className={styles.data}>1.5M</p>
                  <p className={styles.label}>{t('tradingInfo.infoLabel.copyRelationship')}</p>
                </div>
                <div>
                  <p className={styles.data}>100k+</p>
                  <p className={styles.label}>{t('tradingInfo.infoLabel.dailyOrders')}</p>
                </div>
              </div>
              {!user ? (
                <div
                  className={styles.loadingButton}
                  onKeyDown={handleRedirect}
                  onClick={handleRedirect}
                  data-path="/"
                  role="presentation"
                >
                  <p>{t('banner.getStart')}</p>
                  <div className={styles.arrow}><SideBarIcon icon="arrowRight" /></div>
                </div>
              ) : <div className={styles.loadingButton2} />}
            </div>
            <div className={styles.tradingInfoImage}>
              <Image alt="tradingInfoImage" src={TradingInfoImage} objectFit="contain" />
            </div>
          </div>

          <div className={styles.trustInfo}>
            <div className={styles.trustInfotitle}>
              <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('trustInfo.title') }} />
            </div>
            <div className={styles.trustInfoContent}>
              <div className={styles.security}>
                <div className={styles.securityImage}>
                  <Image alt="Security" src={Security} objectFit="contain" priority />
                </div>
                <div className={styles.securityText}>
                  <h2 dangerouslySetInnerHTML={{ __html: t('trustInfo.benefit1') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('trustInfo.des1') }} />
                </div>
              </div>
              <div className={styles.protection}>
                <div className={styles.protectionImage}>
                  <Image alt="Protection" src={Protection} objectFit="contain" priority />
                </div>
                <div className={styles.protectionText}>
                  <h2 dangerouslySetInnerHTML={{ __html: t('trustInfo.benefit2') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('trustInfo.des2') }} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.downloadCon}>
            <DownloadPage />
          </div>

          <div className={styles.startEarning}>
            <div className={styles.startEarningLeftIcon}>
              <Image alt="StartEarningLeft" src={StartEarningLeft} objectFit="cover" />
            </div>
            <div className={styles.startEarningtitle}>
              <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('startEarning.title') }} />
              {!user ? (
                <div
                  className={styles.loadingButton}
                  onKeyDown={handleRedirect}
                  onClick={handleRedirect}
                  data-path="/"
                  role="presentation"
                >
                  <p>{t('banner.getStart')}</p>
                  <div className={styles.arrow}><SideBarIcon icon="arrowRight" /></div>
                </div>
              ) : <div className={styles.loadingButton2} />}
            </div>
            <div className={styles.startEarningRightIcon}>
              <Image alt="StartEarningRight" src={StartEarningRight} objectFit="cover" />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export async function getStaticProps(props) {
  const defaultProps = await pageProps(props);
  return { props: defaultProps };
}

export default observer(Home);
